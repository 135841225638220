<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vs-popup class="holamundo" :buttonCloseHidden="true" title="Ajouter un nom commercial" :active.sync="popupNomCommercial">
        <div class="vx-row">
          <div class="vx-col w-full">
            <label for="Libelle">Libelle</label>
            <vs-input class="w-full" :value="Libelle" @change.native="Libelle=$event.target.value" />
          </div>
        </div>
        <vs-row vs-type="flex" vs-justify="flex-end" class="mt-5">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-button  color="danger" type="filled" @click="popupNomCommercial=false">Annuler</vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-button color="primary" type="filled" @click="createNomCommercialVerre">Valider</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <vs-popup class="holamundo" title="Modifier nom commercial" :active.sync="popupUpdateNomCommercial">
        <div class="vx-row">
          <div class="vx-col w-full">
            <label for="Libelle">Libelle</label>
            <vs-input class="w-full" :value="Libelle" @change.native="Libelle=$event.target.value" />
          </div>
        </div>
        <vs-row vs-type="flex" vs-justify="flex-end" class="mt-5">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-button  color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" @click="deleteNomCommercialVerre">Supprimer</vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <vs-button color="primary" type="filled" @click="updateNomCommercialVerre">Modifier</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <vx-card title="Ajouter un verre au stock">
        <div class="vx-row">
          <div class="vx-col w-1/2">
            <div class="vx-row">
              <div class="vx-col w-1/2 mt-5">
                <label for="SphOG">Type de verre</label>
                <vs-select  autocomplete class="w-full select-large" v-model="showtypeVerre">
                  <vs-select-item value="progressif" text="Progressif" class="w-full" />
                  <vs-select-item value="unifocaux" text="Unifocaux" class="w-full" />
                </vs-select>
              </div>
              <div class="vx-col w-1/2 mt-5">
                <label for="SphOG">Fournisseur</label>
                <vs-select  autocomplete class="w-full select-large" v-model="IdFour">
                  <vs-select-item :key="index" :value="item._id" :text="item.Fournisseur" v-for="(item,index) in fournisseur_verres_data" class="w-full" />
                </vs-select>
              </div>
              <div class="vx-col w-full mt-5">
                <label for="SphOG">Nom commercial du verre <feather-icon v-if="NomCommercialVerre !== 'Nouveau'&&NomCommercialVerre !== null" icon="Edit2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="showUpdateGameVerre(NomCommercialVerre)"/></label>
                <vs-select  autocomplete class="w-full select-large" v-model="NomCommercialVerre" @change="OnNomCommercialChange(NomCommercialVerre)">
                  <vs-select-item  value="Nouveau" text="Nouveau" class="w-full" />
                  <vs-divider/>
                  <vs-select-item :key="index" :value="item._id" :text="item.Libelle" v-for="(item,index) in nomCommercialVerres" class="w-full" />
                </vs-select>
              </div>
              <div class="vx-col w-full mt-5">
                <label for="Observation">Observation</label>
                <vs-textarea rows="8" :value="Observation" @change.native="Observation=$event.target.value" />
              </div>
            </div>
          </div>
          <div class="vx-col w-1/2">

            <div class="vx-row mt-5 p-10" v-if="showtypeVerre === 'progressif' " style="background-color: #ebebeb; border-radius: 5px;">
              <div class="vx-col w-full" v-if="showtypeVerre === 'progressif' ">
                <div class="vx-row" >
                  <div class="vx-col w-full">
                    <label for="Oeil">Oeil</label>
                    <vs-select  autocomplete class="w-full select-large" v-model="Oeil">
                      <vs-select-item value="OD" text="Oeil droit" class="w-full" />
                      <vs-select-item value="OG" text="Oeil gauche" class="w-full" />
                    </vs-select>
                  </div>
                  <div class="vx-col w-1/4 mt-5">
                    <label for="Sph">Sphère</label>
                    <vs-input class="w-full" :value="Sph" @change.native="FormateSPH($event.target.value)" />
                  </div>
                  <div class="vx-col w-1/4 mt-5">
                    <label for="Cyl">Cylindre</label>
                    <vs-input class="w-full" :value="Cyl" @change.native="FormateCYL($event.target.value)" />
                  </div>
                  <div class="vx-col w-1/4 mt-5" v-if="showtypeVerre === 'progressif' ">
                    <label for="Axe">Axe</label>
                    <vs-input class="w-full" :value="Axe" @change.native="Axe=$event.target.value" />
                  </div>
                  <div class="vx-col w-1/4 mt-5">
                    <label for="Addition">Addition</label>
                    <vs-input class="w-full" :value="Addition" @change.native="FormateAdd($event.target.value)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col w-1/2 mt-5" v-if="showtypeVerre === 'progressif' ">
                <label for="PrixVente">Prix vente</label>
                <vs-input class="w-full" :min="0" type="number" :value="PrixVente" @change.native="PrixVente=$event.target.value" />
              </div>
              <div class="vx-col w-1/2 mt-5" v-if="showtypeVerre === 'progressif' ">
                <label for="PrixAchat">Prix Achât</label>
                <vs-input class="w-full" :min="0" type="number" :value="PrixAchat" @change.native="PrixAchat=$event.target.value" />
              </div>
            </div>
            <div v-if="showtypeVerre === 'unifocaux' " class="vx-row mt-5 p-10" style="background-color: #ebebeb; border-radius: 5px;">
              <div class="vx-col w-1/2 mt-5" v-if="showtypeVerre === 'unifocaux' ">
                <label for="Sph">Sphère</label>
                <vs-input class="w-full" :value="Sph" @change.native="FormateSPH($event.target.value)" />
              </div>
              <div class="vx-col w-1/2 mt-5" v-if="showtypeVerre === 'unifocaux' ">
                <label for="Cyl">Cylindre</label>
                <vs-input class="w-full" :value="Cyl" @change.native="FormateCYL($event.target.value)" />
              </div>
              <div class="vx-col w-full mt-5" v-if="showtypeVerre === 'unifocaux' ">
                <label for="qte">Quantité</label>
                <vs-input class="w-full" :min="0" type="number" :value="qte" @change.native="qte=$event.target.value" />
              </div>
              <div class="vx-col w-1/2 mt-5" v-if="showtypeVerre === 'unifocaux' ">
                <label for="PrixVente">Prix vente</label>
                <vs-input class="w-full" :min="0" type="number" :value="PrixVente" @change.native="PrixVente=$event.target.value" />
              </div>
              <div class="vx-col w-1/2 mt-5" v-if="showtypeVerre === 'unifocaux' ">
                <label for="PrixAchat">Prix achât</label>
                <vs-input class="w-full" :min="0" type="number" :value="PrixAchat" @change.native="PrixAchat=$event.target.value" />
              </div>
            </div>
            <vs-row vs-type="flex" vs-justify="flex-end" class="mt-5">
              <vs-col class="mr-2" vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <vs-button class="text-center" color="danger" type="filled" @click="resetData">Annuler</vs-button>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                <vs-button color="primary" type="filled" @click="createStockVerre">Valider</vs-button>
              </vs-col>
            </vs-row>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mt-5">
      <vx-card title="Filtres">
        <div class="vx-row">
          <div class="vx-col mt-5">
            <label for="SphOG">Type de verre</label>
            <vs-select  autocomplete class="w-full select-large" v-model="showtypeVerre">
              <vs-select-item value="progressif" text="Progressif" class="w-full" />
              <vs-select-item value="unifocaux" text="Unifocaux" class="w-full" />
            </vs-select>
          </div>
        </div>
        <vs-table  v-if="showtypeVerre === 'progressif' " class="w-full"  stripe pagination max-items="50" noDataText="" search :data="verresProg">
          <template slot="header">
            <!-- ACTION - DROPDOWN -->
            <div class="mb-4 ml-5 md:mb-0">

            </div>
          </template>
          <template slot="thead">
            <vs-th>
              Four.
            </vs-th>
            <vs-th>
              Oeil
            </vs-th>
            <vs-th>
              Sph.
            </vs-th>
            <vs-th>
              Cyl.
            </vs-th>
            <vs-th>
              Axe
            </vs-th>
            <vs-th>
              Add.
            </vs-th>
            <vs-th>
              Nom commercial
            </vs-th>
            <vs-th>
              Prix V.
            </vs-th>
            <vs-th>
              Observation
            </vs-th>
            <vs-th>
              Actions
            </vs-th>

          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td>
                {{fournisseurVerreName(tr.IdFour)}}
              </vs-td>

              <vs-td>
                {{tr.Oeil}}
              </vs-td>

              <vs-td>
                {{tr.Sph}}
              </vs-td>

              <vs-td>
                {{tr.Cyl}}
              </vs-td>
              <vs-td>
                {{tr.Axe}}
              </vs-td>

              <vs-td>
                {{tr.Addition}}
              </vs-td>

              <vs-td>
                {{findNomCommercialById(tr.NomCommercialVerre)}}
              </vs-td>

              <vs-td>
                {{moneyFormatter(tr.PrixVente)}}
              </vs-td>

              <vs-td>
                {{tr.Observation}}
              </vs-td>
              <vs-td>
                <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-table  v-if="showtypeVerre === 'unifocaux' " class="w-full"  stripe pagination max-items="50" noDataText="" search :data="verresUnifoco">
          <template slot="header">
            <!-- ACTION - DROPDOWN -->
            <div class="mb-4 ml-5 md:mb-0">

            </div>
          </template>
          <template slot="thead">
            <vs-th>
              Four.
            </vs-th>
            <vs-th>
              Sph.
            </vs-th>
            <vs-th>
              Cyl.
            </vs-th>
            <vs-th>
              Nom commercial
            </vs-th>
            <vs-th>
              Prix V.
            </vs-th>
            <vs-th>
              Qte
            </vs-th>
            <vs-th>
              Observation
            </vs-th>
            <vs-th>
              Actions
            </vs-th>

          </template>
          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td>
                {{fournisseurVerreName(tr.IdFour)}}
              </vs-td>

              <vs-td>
                {{tr.Sph}}
              </vs-td>

              <vs-td>
                {{tr.Cyl}}
              </vs-td>

              <vs-td>
                {{findNomCommercialById(tr.NomCommercialVerre)}}
              </vs-td>

              <vs-td>
                {{moneyFormatter(tr.PrixVente)}}
              </vs-td>

              <vs-td>
                {{tr.qte}}
              </vs-td>

              <vs-td>
                {{tr.Observation}}
              </vs-td>
              <vs-td>
                <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord(data[indextr])" />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      showtypeVerre: 'unifocaux',
      popupNomCommercial: false,
      popupUpdateNomCommercial: false,

      IdFour: null,
      NomCommercialVerre: null,
      Oeil: null,
      Axe: null,
      Addition: null,
      TypeVerre: null,
      Observation: null,
      PrixAchat: null,
      PrixVente: null,

      Sph: null,
      Cyl: null,
      qte: null,

      Libelle: null,

      IdUser: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo'))._id : null,

      idVerreProg: null
    }
  },
  computed: {
    nomCommercialVerres () {
      return this.$store.state.stockVerre.nomVerres
    },
    verresProg () {
      return this.$store.state.stockVerre.verresProg
    },
    verresUnifoco () {
      return this.$store.state.stockVerre.verresUnifoco
    },
    fournisseur_verres_data () {
      return this.$store.state.verre.fournisseurs_verres
    }
  },
  methods: {
    OnNomCommercialChange (data) {
      if (data === 'Nouveau') {
        this.popupNomCommercial = true
        this.NomCommercialVerre = null
      }
    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    findNomCommercialById (id) {
      const nomCom = this.$store.state.stockVerre.nomVerres
      const nomVerre = nomCom ? nomCom.find((v) => v._id === id) : null
      return nomVerre ? nomVerre.Libelle : null
    },
    fournisseurVerreName (id) {
      return this.$store.getters['verre/showFournisseurName'](id)
    },
    FormateAdd (add) {
      //convert string to float and fix
      const value = parseFloat(add).toFixed(2)

      this.Addition = Math.sign(value) === 1 || Math.sign(value) === 0 ? `+${value}` : value
    },
    FormateSPH (sph) {
      //convert string to float and fix
      const value = parseFloat(sph).toFixed(2)

      this.Sph = Math.sign(value) === 1 || Math.sign(value) === 0 ? `+${value}` : value
    },
    FormateCYL (cyl) {
      //convert string to float and fix
      const value = parseFloat(cyl).toFixed(2)

      this.Cyl = Math.sign(value) === 1 || Math.sign(value) === 0 ? `+${value}` : value
    },

    findUnifocoVerreQte (sph, cyl) {
      const data = this.$store.state.stockVerre.verresUnifoco
      const verres = data ? data.filter((v) => {
        if (v.Cyl === cyl && v.Sph === sph) {
          return true
        }
      }) : null

      return verres ? verres.reduce((acc, verre) => {
        return acc + (verre.qte ? verre.qte : 0)
      }, 0) : 0
    },
    showUpdateGameVerre (id) {
      const gameVerre = this.$store.state.stockVerre.nomVerres.length > 0 ? this.$store.state.stockVerre.nomVerres.find((g) => g._id === id) : null
      this.Libelle = gameVerre ? gameVerre.Libelle : null
      this.popupUpdateNomCommercial = true
    },
    updateNomCommercialVerre () {
      const payload = {
        Libelle: this.Libelle,
        id: this.NomCommercialVerre,
        IdUser: this.IdUser
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('stockVerre/updateNomCommercialVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    deleteNomCommercialVerre () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('stockVerre/deleteNomCommercialVerre', this.NomCommercialVerre)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
          this.NomCommercialVerre = null
          this.popupUpdateNomCommercial = false
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    createNomCommercialVerre () {
      const payload = {
        Libelle: this.Libelle,
        IdUser: this.IdUser
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('stockVerre/createNomCommercialVerre', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })

    },
    resetData () {
      this.Sph = null
      this.Cyl = null
      this.Axe = null
      this.Addition = null
      this.Observation = null
      this.Oeil = null
      this.qte = null
      this.PrixVente = null
      this.PrixAchat = null
    },
    createStockVerre () {
      if (this.showtypeVerre === 'progressif') this.createStockVerreProgs()

      if (this.showtypeVerre === 'unifocaux') this.createStockVerreUnifoco()
    },
    createStockVerreProgs () {
      const payload = {
        NomCommercialVerre: this.NomCommercialVerre,
        IdFour: this.IdFour,
        Oeil: this.Oeil,
        Sph: this.Sph,
        Cyl: this.Cyl,
        Axe: this.Axe,
        Addition: this.Addition,
        Observation: this.Observation,
        qte: 1,
        PrixVente: this.PrixVente,
        PrixAchat: this.PrixAchat,
        IdUser: this.IdUser
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('stockVerre/createStockVerreProgs', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resetData()
          this.$vs.loading.close()
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    createStockVerreUnifoco () {
      const payload = {
        NomCommercialVerre: this.NomCommercialVerre,
        IdFour: this.IdFour,
        Sph: this.Sph,
        Cyl: this.Cyl,
        qte: this.qte,
        PrixVente: this.PrixVente,
        PrixAchat: this.PrixAchat,
        Observation: this.Observation,
        IdUser: this.IdUser
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('stockVerre/createStockVerreUnifoco', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.resetData()
          this.$vs.loading.close()
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    confirmDeleteRecord (data) {
      this.idVerreProg = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer le verre  "${data.NomCommercialVerre}`,
        accept: this.deleteVerreProg,
        acceptText: 'Supprimer'
      })
    },
    deleteVerreProg () {
      this.$store.dispatch('stockVerre/deleteStockVerreProgs', this.idVerreProg)
        .then(() => {
          this.idVerreProg = null
        })
        .catch(err => {
          console.error(err)
        })
    },
    getNomCommercialVerres () {
      this.$store.dispatch('stockVerre/getNomCommercialVerres')
        .then()
        .catch(err => {
          console.error(err)
        })
    },
    getStockVerreProgs () {
      this.$store.dispatch('stockVerre/getStockVerreProgs')
        .then()
        .catch(err => {
          console.error(err)
        })
    },
    getStockVerreUnifoco () {
      this.$store.dispatch('stockVerre/getStockVerreUnifoco')
        .then()
        .catch(err => {
          console.error(err)
        })
    },
    getFournisseursVerres () {
      this.$store.dispatch('verre/getFournisseursVerres')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
    this.getNomCommercialVerres()
    this.getStockVerreUnifoco()
    this.getStockVerreProgs()
    this.getFournisseursVerres()
  }
}
</script>
